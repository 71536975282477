<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 36.363 35.001"
    style="enable-background: new 0 0 36.363 35.001"
    xml:space="preserve"
  >
    <g v-if="locale === 'es'">
      <path
        fill="currentColor"
        d="M27.0497,14.5175h-1.1535v7.5733h1.1299c0.9147,0,1.6137-0.2986,2.0969-0.8957
		c0.4833-0.5971,0.7302-1.4517,0.7402-2.5636v-0.5996c0-1.1533-0.2389-2.027-0.7171-2.6221
		C28.6684,14.815,27.9695,14.5175,27.0497,14.5175z"
      />
      <path
        fill="currentColor"
        d="M17.7433,14.5175h-2.1434v3.5611h2.0889c0.6183,0,1.0899-0.1453,1.4146-0.4363
		c0.3249-0.291,0.4873-0.7065,0.4873-1.2469c0-0.5559-0.1637-1.0053-0.491-1.3481S18.3201,14.528,17.7433,14.5175z"
      />
      <path
        fill="currentColor"
        d="M32.1675,0H4.1956C1.8785,0,0,2.1371,0,4.773v25.455c0,2.6359,1.8785,4.773,4.1956,4.773h27.9718
		c2.3172,0,4.1956-2.1371,4.1956-4.773V4.773C36.3631,2.1371,34.4846,0,32.1675,0z M11.921,23.9687H4.6178V12.6245H6.956v9.4663
		h4.965C11.921,22.0908,11.921,23.9687,11.921,23.9687z M20.8108,19.0095c-0.7616,0.6419-1.8153,0.9621-3.161,0.9621h-2.0499v3.9971
		h-2.3383V12.6245h4.4272c0.8521,0,1.6016,0.1558,2.2488,0.4675c0.6467,0.3117,1.1443,0.7545,1.4927,1.3285
		c0.348,0.574,0.522,1.227,0.522,1.9593C21.9522,17.4914,21.5716,18.3681,20.8108,19.0095z M32.2327,18.5616
		c0,1.0546-0.2167,1.992-0.6507,2.8124c-0.4335,0.8208-1.0459,1.4572-1.8355,1.9091c-0.79,0.4519-1.681,0.6806-2.6732,0.6856
		h-3.5155V12.6245h3.4918c0.9977,0,1.8902,0.2247,2.6772,0.6741c0.7875,0.4494,1.4015,1.0883,1.8435,1.9166
		c0.4415,0.8284,0.6622,1.7699,0.6622,2.8242L32.2327,18.5616L32.2327,18.5616z"
      />
    </g>
    <path
      v-else
      id="Exclusion_1"
      fill="currentColor"
      d="M32.161,35.001H4.202c-2.4698-0.1727-4.3403-2.3015-4.194-4.773V4.773
	C-0.1383,2.3015,1.7322,0.1727,4.202,0h27.959c2.4698,0.1727,4.3403,2.3015,4.194,4.773v25.455
	C36.5013,32.6996,34.6308,34.8283,32.161,35.001z M19.078,11.191h-2.921l3.155,14.219h2.949l2.314-9.508l0,0l2.313,9.508h2.949
	l3.155-14.219h-2.92l-1.9139,10.117l-2.334-10.117h-2.48l-2.345,10.1L19.078,11.191z M4.088,11.191V25.41h4.4
	c1.1721,0.0101,2.3265-0.2859,3.349-0.859c0.981-0.5598,1.7795-1.3907,2.3-2.393c0.5565-1.0904,0.8364-2.301,0.8149-3.525v-0.654
	c0.0199-1.2305-0.2656-2.4468-0.8311-3.54c-0.5232-1.0051-1.3248-1.8383-2.309-2.4c-1.0243-0.5716-2.1812-0.863-3.354-0.845
	L4.088,11.191z M8.4339,23.0571h-1.417V13.56h1.4449c1.0029-0.0625,1.9766,0.3519,2.627,1.118c0.662,0.9625,0.9791,2.1205,0.9,3.286
	v0.752c0.061,1.1449-0.2659,2.277-0.928,3.213c-0.6482,0.7703-1.6218,1.1895-2.6268,1.131L8.4339,23.0571z"
    />
  </svg>
</template>

<script lang="ts" setup>
const { locale } = useI18n()
</script>
